.aiEnabled {
  animation: blinker 2s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}
.aiDisabled {
  animation: "";
}

.noCursor {
  cursor: none;
  caret-color: transparent; /* Hide the blinking cursor */
}