
.wrapper {
  width: 100%;
  & .table tr:nth-child(odd) td {
      background-color: #eeeff0;
  }
}

.itemsHeaderRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2px;
}

.itemsHeaderTitleColumn {
  flex: 1;
}

.itemsHeaderPaginationColumn {
  flex: 2;
}

.paginator {
  float:right
}