.wrapper {
  width: 100%;
  & .table tr:nth-child(odd) td {
    background-color: #eeeff0;
}
}

.itemsHeaderRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2px;
}

.itemsHeaderTitleColumn {
  flex: 1;
}

.itemsHeaderPaginationColumn {
  flex: 2;
}

.paginator {
  float:right
}

.topBar{
width: 100%;
height: auto;
display: flex;
align-items: center;
justify-content: space-between;
& h1{
    font-size: 24px;
    line-height: 28px;
    color: #000;
    font-weight: normal !important;
    margin: 0;
}
}

.rbacContent {
width: 100%;
height: auto;
display: flex;
flex-direction: row;
}

.rbacContentLayout {
padding: 3px 5px 3px 10px;
box-sizing: border-box;
display: flex;
width: 80%;
height: auto;
border-radius: 5px;
margin-right: 5px;
margin-bottom: 5px;
border: solid 1px #eeecec;
}

.rbacTabsLayout {
width: 100%;
}

.tabsAndChartWrapper {
  width: 100%;
  height: auto;
  display: flex;
  align-items:flex-start;
  justify-content: space-between;
  padding: 15px 24px 15px 10px;
  box-sizing: border-box;
}

.tabsWrapper {
  width: 100%;
  height: auto;
  // padding: 10px 24px;
  // min-height: 350px;
  // background: #fff;
  // box-sizing: border-box;
  position: relative;
}

.rightWrapper {
  width: 100%;
  height: auto;
  padding: 15px 10px 15px 0;
  box-sizing: border-box;
  // border: solid 1px red;

}
.btn_div {
  width: 90px;
  height: 30px;
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 0;

  & .ant-btn.ant-btn-primary {
    height: 30px !important;
  }
}

.titleandfiltrer {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .titleBox {
    width: 30%;
    & h1 {
      font-size: 20px;
      color: #222222;
      line-height: 24px;
      margin-bottom: 3px;
      font-weight: 600;
    }
    & p {
      font-size: 14px;
      color: #9BA1A6;
      margin-bottom: 0;;
    }
  }
  & .dropDownArea {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content:flex-end;
    & button {
      
        border-radius: 6px !important;
    }
  }
}

.dropDownArea.ant-btn {
margin-left: 0 !important;
}

.paginationsvg {
  & svg {
      width: 14px !important;
      height: 14px !important;
  }
}

.selectDropDownBtn {
  width: auto;
  display: inline-block;
  margin-left: 10px !important;
  & Button {
      border: solid 1px #ccc !important;
      border-radius: 6px !important;
      color: #666 !important;
      font-weight: normal !important;
      background: #ffffff !important;
  }
}


.form {
  padding: 10px 18px;
}

.fromp {
  font-size: 16px;
  margin-bottom: 5px;
  text-align: left;
}

.sampleTxt {
  font-size: 12px;
  color: #666;
}

.labletxt {
  font-size: 14px;
  color: #474646;
  font-weight: 600;
  & svg {
    color: red;
    width: 12px !important;
    height: 12px !important;
    margin-left: 7px;
  }
}


.drawerBody {
  width: 100%;
  padding: 12px 18px;
  box-sizing: border-box;
  margin-bottom: 80px;
  & h2 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    // margin-bottom: 0;
    color: #474747;
  }
  & .dataCardcenter {
    width: 100%;
    height: 40px;
    background: #e0e1fa;
    padding: 5px 10px;
    border-radius: 6px;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    & p {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
  & .dataCard {
    border-top: 0;
    border-bottom: solid 1px #e0e1fa;
    border-left: solid 1px #e0e1fa;
    border-right: solid 1px #e0e1fa;
    height: auto;
    border-radius: 6px;
    margin-bottom: 15px;

    & .dataHeader {
      width: 100%;
      height: 40px;
      background: #e0e1fa;
      padding: 5px 10px;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      & p {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
      }
      & svg {
        height: 8px !important;
        height: 8px !important;
      }
    }
  }
}

.assignedRoles {
  width: 100%;
  height: 100px;
  margin-bottom: 24px;
  padding: 12px;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  overflow-y: scroll;
  & div {
    width: fit-content;
    padding: 2px;
    background: #f2f2f2;
    border: 1px solid #dadada;
    border-radius: 5px;
    & span > svg {
      width: 14px !important;
    }
  }
}

.emptyAssignedRoles {
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  padding: 12px;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  background: #fff;
  text-align: center;
  margin-top: 70px;
  & .warningMessage {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fce4e4;
    border-radius: 10px;
    color: #ff0000;
  }
}


.NewRoles {
  position: absolute;
  z-index: 100;
  top: 30px;
  left: 0;
  margin: 0 auto 20px auto;
  width: 100%;
  & .roleOption {
  display: block;
  overflow: hidden;
  overflow-y: scroll;
  height: auto;
  max-height: 140px;
  padding: 0 5px;
  }
}


.dataContent {
  position: relative;
  & p {
    font-size: 12px !important;
    font-weight: 700;
    margin-top: 12px;
  }
}

.RoleLists {
  display: flex;
  justify-content:flex-start;
  flex-direction: row;
  overflow: hidden;
  flex-wrap: wrap;
  
  & .roleList {
      padding: 3px 5px 3px 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #F2F2F2;
      width: auto;
      height: auto;
      border-radius: 20px;
      margin-right: 5px;
      margin-bottom: 5px;
      border: solid 1px #dadada;
      & p {
          font-size: 12px;
          color: #000;
          margin-bottom: 0;
          margin-right: 10px;
          font-weight: normal !important;
          margin-top: 0;
      }
      & svg {
          width: 14px !important;
          height: 14px !important;
          color: #FF0000 !important;
          margin-left: 10px;
          
      }
       & img {
          border-radius: 100%;
          height: 20px;
          width: 20px;
          object-fit: contain;
       }
  }
}
