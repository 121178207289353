.wrapper {
  margin-left: 45px;
  width: 70%;
  box-sizing: border-box;
  height: auto;
  & p {
    text-align: left;
  }
}

.textAlignRight {
text-align: right !important;
}

.title,
.subTitle,
.userEmail {
  width: 100%;
  //text-align: center !important;
}

// .title{
//     margin-top: 60px;
// }
.subTitle{
    margin-bottom: 20px;
    font-size: 20px;
    text-align: left;
}

.userEmail{
    margin-bottom: 50px;
}

.avatarWrapper {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remindMeForgotPasswordWrapper{
    width: 100%;
    margin: 10px 0 0 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


//------------- Responsive css ---------------

@media(min-height:30px) and (max-height:850px){
   
}

@media screen and (min-device-width: 481px) and (max-device-width: 768px) { 

  .wrapper{
      margin-left: 0;
      width: 100%;
  }

  .remindMeForgotPasswordWrapper{
    width: 100%;
  }
  
}

@media only screen and (max-device-width: 480px) {

  .wrapper{
      margin-left: 0;
      width: 100%;
  }
  .remindMeForgotPasswordWrapper{
    width: 100%;
  }
  
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {

  .wrapper{
      margin-left: 0;
      width: 100%;
  }
  .remindMeForgotPasswordWrapper{
    width: 100%;
  }
  
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {

  .wrapper{
      margin-left: 0;
      width: 100%;
  }
  .remindMeForgotPasswordWrapper{
    width: 100%;
  }
  
}

@media only screen and (max-device-width : 1024px) {
  .wrapper{
      margin-left: 0;
      width: 100%;
  }
  .remindMeForgotPasswordWrapper{
    width: 100%;
  }
}
