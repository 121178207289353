.headerBar{
  //position: sticky;
  top: 0px;
  z-index: 1;
}

.specView {
  margin-top: 30px;
  height: 100vh;
  overflow-y: "scroll";
  overflow-x: "hidden";
}

.deleteCondButton {
  position: absolute;
  right: 0;
  cursor: pointer;
  margin-top: -12px;
  margin-right: 5px;
  &:hover {
    border-color: #2B0792 !important;
  }
}

.deleteCondIcon {
  & svg {
      color: #2B0792;
  }
}