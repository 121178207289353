.wrapper{
    width: 100%;
    box-sizing: border-box;
    height: auto;
}

.FormDiv {
    width: 85%;
    margin: 20% 0;
}

.iconWrapper{
    width: 100%;
    margin: 0 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    // this is for the icon
    & svg {
        font-size: 100px;
    }
}

.verificationTitle{
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
}


.verificationDescription{
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

.bold{
    font-weight: bold;
}

//------------- Responsive css ---------------

@media(min-height:30px) and (max-height:850px){
   
  }
  
  @media screen and (min-device-width: 481px) and (max-device-width: 768px) { 
  
    .wrapper{
        margin-left: 0;
        width: 100%;
    }
    
  }
  
  @media only screen and (max-device-width: 480px) {
  
    .wrapper{
        margin-left: 0;
        width: 100%;
    }
    
  }
  
  @media only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) {
  
    .wrapper{
        margin-left: 0;
        width: 100%;
    }
    
  }

  @media only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : landscape) {
  
    .wrapper{
        margin-left: 0;
        width: 100%;
    }
    
  }
  
  @media only screen and (max-device-width : 1024px) {
    .wrapper{
        margin-left: 0;
        width: 100%;
    }
  }