.itemDragged {
    animation: blinker 1s linear infinite;
  }
  @keyframes blinker {
    50% {
      opacity: 0.2;
    }
  }
  .itemNormal {
    animation: "";
  }