.wrapper {
    width: 100%;
}

.topBar{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & h1{
      font-size: 24px;
      line-height: 28px;
      color: #000;
      font-weight: normal !important;
      margin: 0;
  }
}

.developerContent {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.developerContentLayout {
  padding: 3px 5px 3px 10px;
  box-sizing: border-box;
  display: flex;
  width: 80%;
  height: auto;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: solid 1px #eeecec;
}

.developerTabsLayout {
  width: 100%;
}

.wrapper {
  width: 100%;
  & .table tr:nth-child(odd) td {
      background-color: #eeeff0;
  }
}

.itemsHeaderRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2px;
}

.itemsHeaderTitleColumn {
  flex: 1;
}

.itemsHeaderPaginationColumn {
  flex: 2;
}

.paginator {
  float:right
}

