.wrapper{
    margin-left: 45px;
    width: 70%;
    box-sizing: border-box;
    height: auto;
    & p {
        text-align: left;
    }
}

.iconWrapper{
    width: 100%;
    margin: 0 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    // this is for the icon
    & svg {
        font-size: 100px;
    }
}

.verificationTitle{
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
}


.verificationDescription{
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    text-align: center !important;
}

.bold{
    font-weight: bold;
}

//Responsive css

@media(min-height:30px) and (max-height:850px){

   
}

@media screen and (min-device-width: 481px) and (max-device-width: 768px) { 

  .wrapper {
      margin-left: auto;
      width: 100%;
    }
  .formWrap {
      width: 100%;
      margin: 0 auto;
  }

  .FormDiv {
      width: 100% !important;
      margin-left: 0 !important;
      margin: 0 auto;
  }
 
}

@media only screen and (max-device-width: 480px) {

  .wrapper {
      margin-left: auto;
      width: 100%;
    }
  .formWrap {
      width: 100%;
      margin: 0 auto;
  }

  .FormDiv {
      width: 100% !important;
      margin-left: 0 !important;
      margin: 0 auto;
  }

  h1 {
      text-align: center;
   }
 
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {

  .wrapper {
      margin-left: auto;
      width: 100%;
    }
  .formWrap {
      width: 100% !important;
      margin: 0 auto !important;
      box-sizing: border-box !important;
  }

  .FormDiv {
      width: 90% !important;
      margin-left: 0 !important;
      margin: 0 auto !important;
      box-sizing: border-box !important;
  }

  h1 {
     text-align: center;
  }

}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {

  .wrapper {
      margin-left: auto;
      width: 100%;
    }
  .formWrap {
      width: 100% !important;
      margin: 0 auto !important;
      box-sizing: border-box !important;
  }

  .FormDiv {
      width: 90% !important;
      margin-left: 0 !important;
      margin: 0 auto !important;
      box-sizing: border-box !important;
  }

  h1 {
     text-align: center;
  }

}
