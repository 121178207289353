.headerBar {
  padding: 1.2rem 1.2rem;
  height: 30px;
  background: rgba(138, 109, 223, 0.28);
  margin: 0px 0px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  & .specName {
    margin-top: 24px;
    transform: translate(-50%, -50%);
    & a{
      font-size: 18px;
      text-align: center;
      color: #000;
      text-decoration: underline;
    }
  }

  & .linkButton{
    height: 20px !important;
    width: 20px;
    background: rgba(211, 210, 213, 0.28);
    border: 1px solid rgba(182, 165, 214, 0.28);;
    &:hover {
      background-color: transparent;
      border: 1px solid #2B0792 !important;
      color: black;
    }
  }

  & .headerButton{
    height: 30px !important;
    color: #fff;
    padding: 1px 10px;
    background: #7E3985 !important;
    cursor: pointer;
    font-size: 12px;
  }

  & .headerOptions{
    margin-top: 8px;
    height: 25px;
    width: 8px;
  }
}