.labeltxt {
  font-size: 14px;
  color: #474646;
  font-weight: 600;
  & svg {
      color: red;
      width: 12px !important;
      height: 12px !important;
      margin-left: 7px;
  }
}

.deleteCondButton {
  position: absolute;
  right: 0;
  cursor: pointer;
  margin-top: -12px;
  margin-right: 5px;
  &:hover {
    border-color: #2B0792 !important;
  }
}

.deleteCondIcon {
  & svg {
      color: #2B0792;
  }
}