.wrapper{
    // margin-left: 45px;
    width: 80%;
    box-sizing: border-box;
    height: auto;
}

.iconWrapper{
    width: 100%;
    margin: 0 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    // this is for the icon
    & svg {
        font-size: 100px;
    }
}

.verificationTitle{
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
}


.verificationDescription{
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

.bold{
    font-weight: bold;
}

.timer {
    text-align: center;
    font-size: 50px;
    color: #8825AB;
    line-height: 50px;
    margin-bottom: 25px;
}
.resndOTPlink {
    float: right;
    font-weight: bold;
    text-decoration: underline;
    color: #6644C8;
    cursor: pointer;
    & :hover {
        text-decoration: none;
    }
}