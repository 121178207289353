h1 {
    color: #000000;
    font-size: 26px;
    font-weight: bold;
}

.formWrap {
    margin-left: 45px;
    width: 100%;
    box-sizing: border-box;
    height: auto;
}

.FormDiv {
    width: 100%;
}

.fromp {
    font-size: 16px;
    margin-bottom: 5px;
    text-align: left;
}


.la {
    text-align: left;
}

.or {
    position: relative;
    width: 100%;
    height: 50px;
    
    line-height: 50px;
    text-align: center;
  }
  
  .or::before,
  .or::after {
    position: absolute;
    width: 45%;
    height: 1px;
    
    top: 24px;
    
    background-color: #aaa;
    
    content: '';
  }
  
  .or::before {
    left: 0;
  }
  
  .or::after {
    right: 0;
  }


  .loginbox {
    width: 100%;
}

.loginbox:hover {
	  box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

.loginbox h2 {
    margin: 20px 0 20px;
    padding: 0;
    text-transform: uppercase;
    color: #4688F1;
}

.agreeCheckWrapper{
    display: flex;
    justify-content: flex-start;

    & a{
        margin-top: 6px;
    }
}

.googleconnect {
    background-position: 25px 0px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: inline-block;
    width: 100%;
    height: 50px;
	line-height: 50px;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
	border-radius: 6px;
    margin: 10px auto;
    outline: rgb(255, 255, 255) none 0px;
    padding-left: 20%;
    transition: all 0.2s cubic-bezier(0.72, 0.01, 0.56, 1) 0s;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    background: rgb(255, 255, 255) url('../../assets/icons/company/Gmail.png') no-repeat scroll 5px 8px / 41px 41px padding-box border-box;
    //: 1px solid rgb(220, 74, 61);
    box-shadow: 2px 2px 5px 2px #cccc;
}

.googleconnect:hover {
	  border-color: rgb(220, 74, 61);
	  background: #DFD6FB url('../../assets/icons/company/Gmail.png') no-repeat scroll 5px 8px / 41px 41px padding-box border-box;
	  -webkit-transition: all .8s ease-out;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease-out;
}

.googleconnect span {
    color: #000000;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    border: 0px none rgb(220, 74, 61);
    outline: rgb(255, 255, 255) none 0px;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.googleconnect:hover span {
    color: #000000;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.microConnect {
    background-position: 25px 0px;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    cursor: pointer;
    display: inline-block;
    width: 100%;
    height: 50px;
	line-height: 50px;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
	border-radius: 6px;
    margin: 10px auto;
    outline: rgb(255, 255, 255) none 0px;
    padding-left: 20%;
    transition: all 0.2s cubic-bezier(0.72, 0.01, 0.56, 1) 0s;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    background: rgb(255, 255, 255) url('../../assets/icons/company/Microsoft.png') no-repeat scroll 15px 14px / 23px 23px padding-box border-box;
    box-shadow: 2px 2px 5px 2px #cccc;
}

.microConnect:hover {
	  background: #DFD6FB url('../../assets/icons/company/Microsoft.png') no-repeat scroll 15px 15px / 23px 23px padding-box border-box;
	  -webkit-transition: all .8s ease-out;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease-out;
}

.microConnect span {
	  box-sizing: border-box;
    color: rgb(25, 3, 1);
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    border: 0px none rgb(220, 74, 61);
    outline: rgb(0, 0, 0) none 0px;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.microConnect:hover span {
	  color: #000000;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}



//Responsive css

@media(min-height:30px) and (max-height:850px){
    .childrenWrapper{ 
        height: auto;
    }
  }

@media screen and (min-device-width: 481px) and (max-device-width: 768px) { 
    .formWrap {
        width: 100%;
        margin: 0 auto;
    }

    .FormDiv {
        width: 100% !important;
        margin-left: 0 !important;
        margin: 0 auto;
    }
   
}

@media only screen and (max-device-width: 480px) {
    .formWrap {
        width: 100%;
        margin: 0 auto;
    }

    .FormDiv {
        width: 100% !important;
        margin-left: 0 !important;
        margin: 0 auto;
    }

    h1 {
        text-align: center;
     }
   
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
    .formWrap {
        width: 100% !important;
        margin: 0 auto !important;
        box-sizing: border-box !important;
    }

    .FormDiv {
        width: 90% !important;
        margin-left: 0 !important;
        margin: 0 auto !important;
        box-sizing: border-box !important;
    }

    h1 {
       text-align: center;
    }

}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
    .formWrap {
        width: 100% !important;
        margin: 0 auto !important;
        box-sizing: border-box !important;
    }

    .FormDiv {
        width: 90% !important;
        margin-left: 0 !important;
        margin: 0 auto !important;
        box-sizing: border-box !important;
    }

    h1 {
       text-align: center;
    }

}