.wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    & .container{
        width: fit-content;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        & .heading{
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        & .subHeading{
            font-size: 25px;
            font-weight: 600;
        }
    }
}
