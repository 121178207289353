.mainWrapper {
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    background-image: linear-gradient(to right, #623FC7 , #623FC7, #D672CC);
}

.childrenWrapper{
    height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.wrapRight {
    width: 55%;
    height: auto;
    background-repeat: no-repeat;
    background-size: 100% 100% cover;
    background-position: center;
    background-image: linear-gradient(to right , #623FC7 1%, transparent 20%),
    linear-gradient(to left , #623FC7 1%, transparent 20%),
    linear-gradient(to top , #623FC7 5%, transparent 20%),
    linear-gradient(to bottom , #623FC7 10%, transparent 20%),
                    url('../../assets/icons//signup//SignupRightSideBackGround.png');
}

.logo {
    width: 275px;
    height: auto;
}

.rightContent {
    width: 100%;
    margin: 15px auto 15px 0;
    float: none;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.rightContent h1 {
    color: #fff;
    font-size: 42px;
    text-align: left;
    font-weight: normal;
    border-bottom: solid 3px #ffffff;
    line-height: 100px;
    margin-bottom: 40px;
} 

.rightContent ul {
    padding: 0;
    margin: 0;
    margin-left: 25px;
}

.rightContent ul li {
    float: none;
    list-style-image: url(../../assets/icons/common/Listicon.png);
    color: white;
    font-size: 18px;
    text-align: left;
    margin-bottom: 20px;
}

.imgDiv {
    width: 100%;
    height: auto;
    margin-top: 30px;
}
.caption {
    margin-left: 45px;
    margin-bottom: 20px;
}

// Responsive css

@media(min-height:30px) and (max-height:850px){
    .childrenWrapper{ 
        height: 90%;
    }
}

@media screen and (min-device-width: 481px) and (max-device-width: 768px) { 
    .mainWrapper {
        width: 100%;
        height: auto;
        min-height: auto;
        display: block !important;
        
    }
    .wrapLeft {
        width: 100% !important;
        height: auto;
        padding: 30px;
        clip-path: none;
        box-sizing: border-box;
        text-align: center;
        clip-path: ellipse(130% 100% at 50% 0%);
        padding-bottom: 70px;
    }
    .caption {
        margin-left: 0;
        margin: 0 auto  30px!important;
    }
    .wrapRight {
        width: 100%;
        height: auto;
    }

    .rightContent {
        width: 80%;
        margin: 0 auto;
        padding-bottom: 50px;
    }

    .rightContent h1 {
        font-size: 26px !important;
        line-height: 70px;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .childrenWrapper{
        height: auto;
        display: block;
    }
}

@media only screen and (max-device-width: 480px) {
    .mainWrapper {
        width: 100%;
        height: auto;
        min-height: auto;
        display: block !important;
        
    }
    .wrapLeft {
        width: 100% !important;
        height: auto;
        padding: 30px;
        clip-path: none;
        box-sizing: border-box;
        text-align: center;
        clip-path: ellipse(130% 100% at 50% 0%);
        padding-bottom: 70px;
    }
    .caption {
        margin-left: 0;
        margin: 0 auto  30px!important;
    }
    .wrapRight {
        width: 100%;
        height: auto;
    }

    .rightContent {
        width: 80%;
        margin: 0 auto;
        padding-bottom: 50px;
    }

    .rightContent h1 {
        font-size: 26px !important;
        line-height: 70px;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .childrenWrapper{
        height: auto;
        display: block;
    }
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
    .mainWrapper {
        width: 100%;
        height: auto;
        min-height: auto;
        display: block !important;
        
    }
    .wrapLeft {
        width: 100% !important;
        height: auto;
        padding: 30px;
        clip-path: none;
        box-sizing: border-box;
        text-align: center;
        clip-path: ellipse(100% 90% at 50% 10%);
        padding-bottom: 80px;
    }
    .caption {
        margin-left: 0;
        margin: 0 auto  30px!important;
    }
    .wrapRight {
        width: 100%;
        height: auto;
    }

    .rightContent {
        width: 80%;
        margin: 0 auto;
        padding-bottom: 50px;
    }

    .rightContent h1 {
        font-size: 36px !important;
        line-height: 70px;
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .childrenWrapper{
        height: auto;
        display: block;
    }
}

@media only screen and (max-device-width : 1024px) {
    .childrenWrapper > div:first-child{
        margin: auto;
    }
  }

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {

    .mainWrapper {
        width: 100%;
        height: auto;
        min-height: auto;
        display: block !important;
        
    }
    .wrapLeft {
        width: 100% !important;
        height: auto;
        padding: 30px;
        clip-path: none;
        box-sizing: border-box;
        text-align: center;
        clip-path: ellipse(100% 90% at 50% 10%);
        padding-bottom: 80px;
    }
    .caption {
        margin-left: 0;
        margin: 0 auto  30px!important;
    }
    .wrapRight {
        width: 100%;
        height: auto;
    }

    .rightContent {
        width: 80%;
        margin: 0 auto;
        padding-bottom: 50px;
    }

    .rightContent h1 {
        font-size: 36px !important;
        line-height: 70px;
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .childrenWrapper{
        height: auto;
        display: block;
    }
 
}